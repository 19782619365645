


import React from 'react';
import { Link } from 'react-router-dom';
import hostel from '../assets/Logo/hostel.webp';
import PG1 from '../assets/Logo/PG1.webp';
import dormitory from '../assets/Logo/dormitory.webp';
import flat from '../assets/Logo/flat.webp';
import apartment from '../assets/Logo/apartment.webp';

const Item = () => {
  return (
    <div className="w-9/12 mb-10">
     
     {/* PG */}
    <div className="w-full mb-12 ">
        <div className="flex-col items-center justify-center">
            <Link to="/catalog/PG"> 
                <img
                src={PG1}
                alt="PG"
                loading="lazy"
                className="sm:h-60 md:h-64 lg:h-[24rem] w-full shadow-[0_0_20px_0]
                 shadow-white rounded-xl object-cover transition-transform 
                duration-500 ease-in-out hover:scale-95  cursor-pointer"
                />
            </Link>    
        </div>
        <div className=" mt-12 ">
            <div className="flex flex-col justify-center gap-6 items-center">
                <p className="text-center text-white text-2xl sm:text-3xl md:text-4xl font-semibold -mt-3
                text-transparent">
                    PG
                </p>
                <p className=" text-center text-sm sm:text-xl font-medium text-richblack-200 lg:w-[90%]">
                    Comfortable and budget-friendly shared accommodations, perfect for students and professionals looking for a home-like environment
                    with a vibrant sense of community.
                </p>
                <Link to="/catalog/PG">
                    <button className="flex  items-center justify-center
                     hover:bg-yellow-100 bg-yellow-50 cursor-pointer gap-x-2 
                    rounded-md py-2 px-20 text-sm sm:text-base md:text-lg font-semibold
                     text-richblack-900">
                    Explore Now
                    </button>
                </Link>
            </div>
        </div>
    </div>
      
      {/* Hostel */}
    <div className="w-full mb-12 ">
        <div className="flex-col items-center justify-center">
            <Link to="/catalog/Hostel"> 
                <img
                src={hostel}
                alt="hostel"
                loading="lazy"
                className="sm:h-60 md:h-64 lg:h-[24rem] w-full shadow-[0_0_20px_0]
                 shadow-white rounded-xl object-cover transition-transform 
                duration-500 ease-in-out hover:scale-95  cursor-pointer"
                />
            </Link>    
        </div>
        <div className=" mt-12 ">
            <div className="flex flex-col justify-center gap-6 items-center">
                <p className="text-center text-white text-2xl sm:text-3xl md:text-4xl font-semibold -mt-3
                text-transparent">
                   Hostel
                </p>
                <p className=" text-center text-sm sm:text-xl font-medium text-richblack-200 lg:w-[90%]">
                    Affordable shared accommodations, ideal for individuals seeking a 
                    budget-friendly stay with a sense of community.
                </p>
                <Link to="/catalog/Hostel">
                    <button className="flex  items-center justify-center
                     hover:bg-yellow-100 bg-yellow-50 cursor-pointer gap-x-2 
                    rounded-md py-2 px-20 text-sm sm:text-base md:text-lg font-semibold
                     text-richblack-900">
                    Explore Now
                    </button>
                </Link>
            </div>
        </div>
    </div>


    {/*Flat */}
      <div className="w-full mb-12 ">
        <div className="flex-col items-center justify-center">
            <Link to="/catalog/Flat"> 
                <img
                src={flat}
                alt="Flat"
                loading="lazy"
                className="sm:h-60 md:h-64 lg:h-[24rem] w-full shadow-[0_0_20px_0]
                 shadow-white rounded-xl object-cover transition-transform 
                duration-500 ease-in-out hover:scale-95  cursor-pointer"
                />
            </Link>    
        </div>
        <div className=" mt-12 ">
            <div className="flex flex-col justify-center gap-4 items-center">
                <p className="text-center text-white text-2xl sm:text-3xl md:text-4xl font-semibold -mt-3
                text-transparent">
                    Flat
                </p>
                <p className=" text-center text-sm sm:text-xl font-medium text-richblack-200 lg:w-[90%]">
                Private living spaces offering convenience and independence, ideal for individuals or 
                families seeking a comfortable and self-contained housing solution.
                </p>
                <Link to="/catalog/Flat">
                    <button className="flex  items-center justify-center hover:bg-yellow-100 bg-yellow-50 cursor-pointer gap-x-2 
                    rounded-md py-2 px-20 text-sm sm:text-base md:text-lg font-semibold
                     text-richblack-900">
                    Explore Now
                    </button>
                </Link>
            </div>
        </div>
    </div>


      {/* Apartment */}
      <div className="w-full mb-12 ">
        <div className="flex-col items-center justify-center">
            <Link to="/catalog/Apartment"> 
                <img
                src={apartment}
                alt="Apartment"
                loading="lazy"
                className="sm:h-60 md:h-64 lg:h-[24rem] w-full shadow-[0_0_20px_0]
                 shadow-white rounded-xl object-cover transition-transform 
                duration-500 ease-in-out hover:scale-95  cursor-pointer"
                />
            </Link>    
        </div>
        <div className=" mt-12 ">
            <div className="flex flex-col justify-center gap-6 items-center">
                <p className="text-center text-white text-2xl sm:text-3xl md:text-4xl font-semibold -mt-3
                text-transparent">
                    Apartment
                </p>
                <p className=" text-center text-sm sm:text-xl font-medium text-richblack-200 lg:w-[90%]">
                Spacious and modern living spaces, 
                perfect for those seeking comfort, privacy, and a well-equipped home environment.
                </p>
                <Link to="/catalog/Apartment">
                    <button className="flex  items-center justify-center hover:bg-yellow-100 bg-yellow-50 cursor-pointer gap-x-2 
                    rounded-md py-2 px-20 text-sm sm:text-base md:text-lg font-semibold
                     text-richblack-900">
                    Explore Now
                    </button>
                </Link>
            </div>
        </div>
    </div>

      {/* Dormitory */}
      <div className="w-full mb-12 ">
        <div className="flex-col items-center justify-center">
            <Link to="/catalog/Dormitory"> 
                <img
                src={dormitory}
                alt="Dormitory"
                loading="lazy"
                className="sm:h-60 md:h-64 lg:h-[24rem] w-full shadow-[0_0_20px_0]
                 shadow-white rounded-xl object-cover transition-transform 
                duration-500 ease-in-out hover:scale-95  cursor-pointer"
                />
            </Link>    
        </div>
        <div className=" mt-12 ">
            <div className="flex flex-col justify-center gap-6 items-center">
                <p className="text-center text-white text-2xl sm:text-3xl md:text-4xl font-semibold -mt-2
                text-transparent">
                    Dormitory
                </p>
                <p className=" text-center text-sm sm:text-xl font-medium text-richblack-200 lg:w-[90%]">
                    Shared housing, especially suitable for student accommodations.
                </p>
                <Link to="/catalog/Dormitory">
                    <button className="flex  items-center justify-center hover:bg-yellow-100 bg-yellow-50 cursor-pointer gap-x-2 
                    rounded-md py-2 px-20 text-sm sm:text-base md:text-lg font-semibold
                     text-richblack-900">
                    Explore Now
                    </button>
                </Link>
            </div>
        </div>
      </div>
    </div>
  );
};

export default Item;

