import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { resetPassword } from "../services/operations/authAPI";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";

const ResetPassword = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const token = location.pathname.split("/").at(-1);

  const [formData, setFormData] = useState({
    password: "",
    confirmPassword: "",
  });
  const [resetComplete, setResetComplete] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const { loading } = useSelector((state) => state.auth);

  const handleOnSubmit = (e) => {
    e.preventDefault();
    if (formData.password === formData.confirmPassword) {
      dispatch(
        resetPassword(formData.password, formData.confirmPassword, token, setResetComplete)
      );
    } else {
      alert("Passwords do not match");
    }
  };

  const handleOnChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <div className="grid min-h-[calc(100vh-3.5rem)] place-items-center px-4 md:px-6 lg:px-8">
      {loading ? (
        <div className="custom-loader"></div>
      ) : (
        <div className="w-full max-w-[500px] p-4 lg:p-8 bg-richblack-900 rounded-lg shadow-md">
          <h1 className="text-[1.875rem] font-semibold leading-[2.375rem] text-richblack-5">
            {!resetComplete ? "Choose a new password" : "Reset complete!"}
          </h1>
          <p className="my-4 text-[1.125rem] leading-[1.625rem] text-richblack-100">
            {!resetComplete
              ? "Almost done. Enter your new password and you're all set."
              : `All done! We have sent an email to ${"nn"} to confirm.`}
          </p>
          <form onSubmit={handleOnSubmit} className="space-y-6">
            {!resetComplete && (
              <>
                <div className="relative">
                  <label className="block">
                    <p className="mb-1 text-[0.875rem] leading-[1.375rem] text-richblack-5">
                      New Password <sup className="text-pink-200">*</sup>
                    </p>
                    <input
                      required
                      type={showPassword ? "text" : "password"}
                      name="password"
                      value={formData.password}
                      onChange={handleOnChange}
                      placeholder="Enter Password"
                      className="w-full rounded-[0.5rem] bg-richblack-800 p-[12px] pr-12 
                      text-richblack-5 shadow-inner"
                    />
                  </label>
                  <span
                    onClick={() => setShowPassword((prev) => !prev)}
                    className="absolute right-3 top-9 z-[10] cursor-pointer"
                  >
                    {showPassword ? (
                      <AiOutlineEyeInvisible fontSize={24} fill="#AFB2BF" />
                    ) : (
                      <AiOutlineEye fontSize={24} fill="#AFB2BF" />
                    )}
                  </span>
                </div>
                <div className="relative">
                  <label className="block">
                    <p className="mb-1 text-[0.875rem] leading-[1.375rem] text-richblack-5">
                      Confirm New Password <sup className="text-pink-200">*</sup>
                    </p>
                    <input
                      required
                      type={showConfirmPassword ? "text" : "password"}
                      name="confirmPassword"
                      value={formData.confirmPassword}
                      onChange={handleOnChange}
                      placeholder="Enter Password"
                      className="w-full rounded-[0.5rem] bg-richblack-800 p-[12px] pr-12 
                      text-richblack-5 shadow-inner"
                    />
                  </label>
                  <span
                    onClick={() => setShowConfirmPassword((prev) => !prev)}
                    className="absolute right-3 top-10 z-[10] cursor-pointer"
                  >
                    {showConfirmPassword ? (
                      <AiOutlineEyeInvisible fontSize={24} fill="#AFB2BF" />
                    ) : (
                      <AiOutlineEye fontSize={24} fill="#AFB2BF" />
                    )}
                  </span>
                </div>
              </>
            )}
            {!resetComplete ? (
              <button
                type="submit"
                className="w-full rounded-[8px] bg-yellow-50 py-[12px] font-medium text-richblack-900"
              >
                Reset Password
              </button>
            ) : (
              <Link to="/login">
                <button className="w-full rounded-[8px] bg-yellow-50 py-[12px] font-medium text-richblack-900">
                  Return to login
                </button>
              </Link>
            )}
          </form>
          <div className="mt-6 text-center">
            <Link to="/login" className="flex items-center justify-center gap-x-2 text-richblack-5">
              <svg
                stroke="currentColor"
                fill="currentColor"
                strokeWidth="0"
                viewBox="0 0 24 24"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M21 11H6.414l5.293-5.293-1.414-1.414L2.586 12l7.707 7.707 1.414-1.414L6.414 13H21z"></path>
              </svg>
              Back To Login
            </Link>
          </div>
        </div>
      )}
    </div>
  );
};

export default ResetPassword;
