
import { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { toast } from "react-hot-toast"
import { HiOutlineCurrencyRupee } from "react-icons/hi"
import { MdNavigateNext } from "react-icons/md"
import { useDispatch, useSelector } from "react-redux"

import {
  addCourseDetails,
  editCourseDetails,
  fetchCourseCategories,
} from "../../../../../services/operations/courseDetailsAPI"
import { setCourse, setStep } from "../../../../../slices/courseSlice"
import { COURSE_STATUS } from "../../../../../utils/constants"
import IconBtn from "../../../../common/IconBtn"
import Upload from "../Upload"
import ChipInput from "./ChipInput"
import RequirementField from "./RequirementField"

export default function CourseInformationForm() {
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm()

  const dispatch = useDispatch()
  const { token } = useSelector((state) => state.auth)
  const { course, editCourse } = useSelector((state) => state.course)
  const [loading, setLoading] = useState(false)
  const [courseCategories, setCourseCategories] = useState([])

  useEffect(() => {
    const getCategories = async () => {
      setLoading(true)
      const categories = await fetchCourseCategories()
      if (categories.length > 0) {
        // console.log("categories", categories)
        setCourseCategories(categories)
      }
      setLoading(false)
    }
    // if form is in edit mode
    if (editCourse) {
      // console.log("data populated", editCourse)
      setValue("courseTitle", course.courseName)
      setValue("courseShortDesc", course.courseDescription)
      setValue("coursePrice", course.price)
      setValue("courseTags", course.tag)
      setValue("courseBenefits", course.whatYouWillLearn)
      setValue("courseCategory", course.category)
      setValue("courseRequirements", course.instructions)
      setValue("courseImage", course.thumbnail)
      setValue("FirstContactNumber", course.FirstContactNumber)
      setValue("SecondContactNumber", course.SecondContactNumber)
      setValue("ThirdContactNumber", course.ThirdContactNumber)
    }
    getCategories()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const isFormUpdated = () => {
    const currentValues = getValues()
    // console.log("changes after editing form values:", currentValues)
    if (
      currentValues.courseTitle !== course.courseName ||
      currentValues.courseShortDesc !== course.courseDescription ||
      currentValues.coursePrice !== course.price ||
      currentValues.FirstContactNumber !== course.FirstContactNumber ||
      currentValues.SecondContactNumber !== course.SecondContactNumber ||
      currentValues.ThirdContactNumber !== course.ThirdContactNumber ||
      currentValues.courseTags.toString() !== course.tag.toString() ||
      currentValues.courseBenefits !== course.whatYouWillLearn ||
      currentValues.courseCategory._id !== course.category._id ||
      currentValues.courseRequirements.toString() !==
        course.instructions.toString() ||
      currentValues.courseImage !== course.thumbnail
    ) {
      return true
    }
    return false
  }

  //   handle next button click
  const onSubmit = async (data) => {
    // console.log(data)

    if (editCourse) {
      // const currentValues = getValues()
      // console.log("changes after editing form values:", currentValues)
      // console.log("now course:", course)
      // console.log("Has Form Changed:", isFormUpdated())
      if (isFormUpdated()) {
        const currentValues = getValues()
        const formData = new FormData()
        // console.log(data)
        formData.append("courseId", course._id)
        if (currentValues.courseTitle !== course.courseName) {
          formData.append("courseName", data.courseTitle)
        }
        if (currentValues.courseShortDesc !== course.courseDescription) {
          formData.append("courseDescription", data.courseShortDesc)
        }
        if (currentValues.coursePrice !== course.price) {
          formData.append("price", data.coursePrice)
        }
        if (currentValues.FirstContactNumber !== course.FirstContactNumber) {
          formData.append("FirstContactNumber", data.FirstContactNumber)
        }
        if (currentValues.SecondContactNumber !== course.SecondContactNumber) {
          formData.append("SecondContactNumber", data.SecondContactNumber)
        }
        if (currentValues.ThirdContactNumber !== course.ThirdContactNumber) {
          formData.append("ThirdContactNumber", data.ThirdContactNumber)
        }
        if (currentValues.courseTags.toString() !== course.tag.toString()) {
          formData.append("tag", JSON.stringify(data.courseTags))
        }
        if (currentValues.courseBenefits !== course.whatYouWillLearn) {
          formData.append("whatYouWillLearn", data.courseBenefits)
        }
        if (currentValues.courseCategory._id !== course.category._id) {
          formData.append("category", data.courseCategory)
        }
        if (
          currentValues.courseRequirements.toString() !==
          course.instructions.toString()
        ) {
          formData.append(
            "instructions",
            JSON.stringify(data.courseRequirements)
          )
        }
        if (currentValues.courseImage !== course.thumbnail) {
          formData.append("thumbnailImage", data.courseImage)
        }
        // console.log("Edit Form data: ", formData)
        setLoading(true)
        const result = await editCourseDetails(formData, token)
        setLoading(false)
        if (result) {
          dispatch(setStep(2))
          dispatch(setCourse(result))
        }
      } else {
        toast.error("No changes made to the form")
      }
      return
    }

    const formData = new FormData()
    formData.append("courseName", data.courseTitle)
    formData.append("courseDescription", data.courseShortDesc)
    formData.append("price", data.coursePrice)
    formData.append("FirstContactNumber", data.FirstContactNumber)
    formData.append("SecondContactNumber", data.SecondContactNumber)
    formData.append("ThirdContactNumber", data.ThirdContactNumber)
    formData.append("tag", JSON.stringify(data.courseTags))
    formData.append("whatYouWillLearn", data.courseBenefits)
    formData.append("category", data.courseCategory)
    formData.append("status", COURSE_STATUS.DRAFT)
    formData.append("instructions", JSON.stringify(data.courseRequirements))
    formData.append("thumbnailImage", data.courseImage)
    
    setLoading(true)
    const result = await addCourseDetails(formData, token)
    if (result) {
      dispatch(setStep(2))
      dispatch(setCourse(result))
    }
    setLoading(false)
  }

  return (
    <form
            onSubmit={handleSubmit(onSubmit)}
            className="space-y-8 mx-4 sm:mx-6 md:mx-10 lg:mx-14 rounded-md border-[1px] border-richblack-700 bg-richblack-800 p-4 sm:p-6"
          >
            {/* Property Name */}
            <div className="flex flex-col space-y-2">
              <label className="text-sm text-richblack-5" htmlFor="courseTitle">
                Property Name <sup className="text-pink-200">*</sup>
              </label>
              <input
                id="courseTitle"
                placeholder="Enter Property Name"
                {...register("courseTitle", { required: false })}
                className="form-style w-full border rounded-[0.5rem] bg-richblack-800 p-3 sm:p-4 text-richblack-5"
              />
              {errors.courseTitle && (
                <span className="ml-2 text-xs tracking-wide text-pink-200">
                  Property Name is required
                </span>
              )}
            </div>

            {/* City Name */}
            <div className="flex flex-col space-y-2">
              <label className="text-sm text-richblack-5" htmlFor="courseShortDesc">
                City Name <sup className="text-pink-200">*</sup>
              </label>
              <textarea
                id="courseShortDesc"
                placeholder="Enter Correct City Name"
                {...register("courseShortDesc", { required: true })}
                className="form-style w-full border rounded-[0.5rem] bg-richblack-800 p-3 sm:p-4 text-richblack-5"
              />
              {errors.courseShortDesc && (
                <span className="ml-2 text-xs tracking-wide text-pink-200">
                  City Name is required
                </span>
              )}
            </div>

            {/* Rent Amount */}
            <div className="flex flex-col space-y-2">
              <label className="text-sm text-richblack-5" htmlFor="coursePrice">
                Rent Amount <sup className="text-pink-200">*</sup>
              </label>
              <div className="relative">
                <input
                  id="coursePrice"
                  placeholder="Enter Rent Amount (Monthly or Daily)"
                  {...register("coursePrice", {
                    required: true,
                    valueAsNumber: true,
                    pattern: {
                      value: /^(0|[1-9]\d*)(\.\d+)?$/,
                    },
                  })}
                  className="form-style pl-10 sm:pl-12 w-full border rounded-[0.5rem] bg-richblack-800 p-3 sm:p-4 text-richblack-5"
                />
                <HiOutlineCurrencyRupee className="absolute left-3 top-1/2 -translate-y-1/2 text-lg sm:text-2xl text-richblack-400" />
              </div>
              {errors.coursePrice && (
                <span className="ml-2 text-xs tracking-wide text-pink-200">
                  Rent Amount is required
                </span>
              )}
            </div>

            {/* Property Category */}
            <div className="flex flex-col space-y-2">
              <label className="text-sm text-richblack-5" htmlFor="courseCategory">
                Property Category <sup className="text-pink-200">*</sup>
              </label>
              <select
                {...register("courseCategory", { required: true })}
                defaultValue=""
                id="courseCategory"
                className="form-style w-full border rounded-[0.5rem] bg-richblack-800 p-3 sm:p-4 text-richblack-5"
              >
                <option value="" disabled>
                  Choose a Category
                </option>
                {!loading &&
                  courseCategories?.map((category, indx) => (
                    <option key={indx} value={category?._id}>
                      {category?.name}
                    </option>
                  ))}
              </select>
              {errors.courseCategory && (
                <span className="ml-2 text-xs tracking-wide text-pink-200">
                  Category is required
                </span>
              )}
            </div>

            {/* Contact Numbers */}
            {["FirstContactNumber", "SecondContactNumber", "ThirdContactNumber"].map((field, idx) => (
              <div key={field} className="flex flex-col space-y-2">
                <label className="text-sm text-richblack-5" htmlFor={field}>
                  {idx === 0 ? "Contact Number" : "Alternate Contact Number"}
                  {idx === 0 && <sup className="text-pink-200">*</sup>}
                </label>
                <input
                  id={field}
                  placeholder={idx === 0 ? "Enter Contact Number" : "Optional"}
                  {...register(field, {
                    required: idx === 0,
                    valueAsNumber: false,
                    pattern: {
                      value: /^(0|[1-9]\d*)(\.\d+)?$/,
                    },
                  })}
                  className="form-style w-full border rounded-[0.5rem] bg-richblack-800 p-3 sm:p-4 text-richblack-5"
                />
                {idx === 0 && errors[field] && (
                  <span className="ml-2 text-xs tracking-wide text-pink-200">
                    Contact Number is required
                  </span>
                )}
              </div>
            ))}

            {/* Selected Category (Tags) */}
            <ChipInput
              label="Selected Category"
              name="courseTags"
              placeholder="Enter Selected Property Category and press Enter"
              register={register}
              errors={errors}
              setValue={setValue}
              getValues={getValues}
            />

            {/* Property Thumbnail Image */}
            <Upload
              name="courseImage"
              label="Property Thumbnail Image"
              register={register}
              setValue={setValue}
              errors={errors}
              editData={editCourse ? course?.thumbnail : null}
            />

            {/* Complete Address */}
            <div className="flex flex-col space-y-2">
              <label className="text-sm text-richblack-5" htmlFor="courseBenefits">
                Complete Address <sup className="text-pink-200">*</sup>
              </label>
              <textarea
                id="courseBenefits"
                placeholder="Enter Complete Address"
                {...register("courseBenefits", { required: true })}
                className="form-style w-full border rounded-[0.5rem] bg-richblack-800 p-3 sm:p-4 text-richblack-5"
              />
              {errors.courseBenefits && (
                <span className="ml-2 text-xs tracking-wide text-pink-200">
                  Complete Address is required
                </span>
              )}
            </div>

            {/* Property Feature Details */}
            <RequirementField
              name="courseRequirements"
              label="Property Feature Details"
              register={register}
              setValue={setValue}
              errors={errors}
              getValues={getValues}
            />

            {/* Buttons */}
            <div className="flex flex-wrap justify-end gap-2">
              {editCourse && (
                <button
                  onClick={() => dispatch(setStep(2))}
                  disabled={loading}
                  className="flex cursor-pointer items-center gap-x-2 rounded-md bg-richblack-300 py-2 px-4 font-semibold text-richblack-900"
                >
                  Continue Without Saving
                </button>
              )}
              <IconBtn
                disabled={loading}
                text={!editCourse ? "Next" : "Save Changes"}
              >
                <MdNavigateNext />
              </IconBtn>
            </div>
    </form>

  )
}