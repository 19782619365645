import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { searchCourses } from '../services/operations/courseDetailsAPI';
import CatalogCard from '../componenets/core/Catalog/CatalogCard';
import { HiOutlineEmojiSad } from 'react-icons/hi';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import "react-loading-skeleton/dist/skeleton.css";

const SearchCourse = () => {
    const [searchResults, setSearchResults] = useState([]);
    const [filteredResults, setFilteredResults] = useState([]);
    const [loading, setLoading] = useState(false);
    const [minPrice, setMinPrice] = useState('');
    const [maxPrice, setMaxPrice] = useState('');
    const [tagQuery, setTagQuery] = useState('');
    const dispatch = useDispatch();
    const { searchQuery } = useParams();

    const fetchSearchResults = async () => {
        setLoading(true);
        const res = await searchCourses(searchQuery, dispatch);
        setSearchResults(res);
        setFilteredResults(res); // Initialize filtered results with all results
        setLoading(false);
    };

    const randomizeArray = (array) => array.sort(() => Math.random() - 0.5);

    const handleFilter = () => {
        // Randomize the application order of filters
        const filterSteps = randomizeArray([
            () => searchResults.filter(course => course.price >= Number(minPrice || 0)),
            () => searchResults.filter(course => course.price <= Number(maxPrice || Infinity)),
            () =>
                searchResults.filter(course =>
                    Array.isArray(course?.tag) && course.tag.some(tag => tag.toLowerCase().includes(tagQuery.toLowerCase()))
                ),
        ]);

        let filtered = [...searchResults];
        filterSteps.forEach(applyFilter => {
            if (applyFilter) {
                filtered = applyFilter(filtered);
            }
        });

        // Randomize the filtered results
        setFilteredResults(randomizeArray(filtered));
    };

    useEffect(() => {
        fetchSearchResults();
    }, [searchQuery]);

    useEffect(() => {
        handleFilter(); // Apply filter whenever search results or filter criteria change
    }, [minPrice, maxPrice, tagQuery, searchResults]);

    return (
        <div>
            <div className="mx-auto flex min-h-[260px] flex-col justify-center gap-4 p-5 bg-richblack-800">
                <p className="text-3xl text-richblack-5">Search Results for {searchQuery}</p>
                <p className="max-w-[870px] text-richblack-200">
                    {filteredResults?.length} results found for {searchQuery}
                </p>
                <div className="lg:flex md:flex">
                    <div className="relative w-full max-w-md">
                        <input
                            type="text"
                            placeholder="Filter by Category (e.g. Hostel, Flat, Apartment, Dormitory)"
                            className="w-full text-xs font-bold py-4 pl-3 pr-16 border border-gray-400 
                          focus:outline-none focus:ring-2 focus:ring-yellow-500"
                            value={tagQuery}
                            onChange={(e) => setTagQuery(e.target.value)}
                        />
                    </div>
                    <div className="flex">
                        <input
                            type="text"
                            placeholder="Min Price"
                            className="w-full text-xs font-bold py-4 pl-3 pr-16 border border-gray-400 
                                       focus:outline-none focus:ring-2 focus:ring-yellow-500"
                            value={minPrice}
                            onChange={(e) => setMinPrice(e.target.value)}
                        />
                        <input
                            type="text"
                            placeholder="Max Price"
                            className="w-full text-xs font-bold py-4 pl-3 pr-16 border border-gray-400 
                                       focus:outline-none focus:ring-2 focus:ring-yellow-500"
                            value={maxPrice}
                            onChange={(e) => setMaxPrice(e.target.value)}
                        />
                    </div>
                </div>
            </div>
            {loading ? (
                <div className="flex gap-6 flex-wrap justify-evenly overflow-hidden m-9">
                    <SkeletonTheme baseColor="#2C333F" highlightColor="#161D29">
                        {[...Array(3)].map((_, index) => (
                            <div key={index}>
                                <Skeleton className="md:h-[200px] lg:w-[400px] h-[100px] w-[200px] rounded-xl" />
                                <Skeleton className="md:h-[20px] w-[70px] rounded-md" />
                                <Skeleton className="md:h-[20px] md:w-[400px] rounded-md" />
                                <Skeleton className="md:h-[20px] md:w-[400px] rounded-md" />
                            </div>
                        ))}
                    </SkeletonTheme>
                </div>
            ) : filteredResults?.length === 0 ? (
                <div className="mx-auto flex min-h-[500px] items-center flex-col justify-center gap-4 p-5 bg-richblack-900">
                    <p className="text-3xl text-richblack-5">No Results Found</p>
                    <HiOutlineEmojiSad className="text-richblack-100 text-[100px]" />
                </div>
            ) : (
                <div className="mx-auto flex flex-wrap p-5 gap-4 justify-evenly m-5">
                    {filteredResults.map((item) => (
                        <div key={item.id} className="flex flex-col gap-4">
                            <CatalogCard course={item} Height={"lg:h-[250px] h-[100px]"} />
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default SearchCourse;
