import React, { useEffect, useState } from "react";
// import { BiInfoCircle } from "react-icons/bi";
// import { HiOutlineGlobeAlt } from "react-icons/hi";
import ReactMarkdown from 'react-markdown';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
// import CTAButton from '../componenets/core/HomePage/Button'
import ConfirmationModal from "../componenets/common/ConfirmationModal";
// import Footer from "../componenets/common/Footer";
import RatingStars from "../componenets/common/RatingStars";
import CourseAccordionBar from "../componenets/core/Course/CourseAccordionBar";
// import CourseDetailsCard from "../componenets/core/Course/CourseDetailsCard";
// import { formatDate } from "../services/formatDate";
import { fetchCourseDetails } from "../services/operations/courseDetailsAPI";
import { buyCourse } from "../services/operations/studentFeaturesAPI";
import GetAvgRating from "../utils/avgRating";
import Error from "./Error";

function CourseDetails() {
  const { user } = useSelector((state) => state.profile) || {};
  const { token } = useSelector((state) => state.auth) || {};
  const { loading } = useSelector((state) => state.profile) || {};
  const { paymentLoading } = useSelector((state) => state.course) || {};
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { courseId } = useParams();

  const [response, setResponse] = useState(null);
  const [confirmationModal, setConfirmationModal] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        const res = await fetchCourseDetails(courseId);
        setResponse(res);
      } catch (error) {
        console.log("Could not fetch Property Details", error);
      }
    })();
  }, [courseId]);

  const [avgReviewCount, setAvgReviewCount] = useState(0);
  useEffect(() => {
    if (response?.data?.courseDetails?.ratingAndReviews) {
      const count = GetAvgRating(response.data.courseDetails.ratingAndReviews);
      setAvgReviewCount(count);
    }
  }, [response]);

  const [isActive, setIsActive] = useState([]);
  const handleActive = (id) => {
    setIsActive(
      !isActive.includes(id)
        ? isActive.concat([id])
        : isActive.filter((e) => e !== id)
    );
  };

  const [totalNoOfLectures, setTotalNoOfLectures] = useState(0);
  useEffect(() => {
    if (response?.data?.courseDetails?.courseContent) {
      let lectures = 0;
      response.data.courseDetails.courseContent.forEach((sec) => {
        lectures += sec.subSection?.length || 0;
      });
      setTotalNoOfLectures(lectures);
    }
  }, [response]);

  if (loading || !response) {
    return (
      <div className="grid min-h-[calc(100vh-3.5rem)] place-items-center">
        <div className="spinner"></div>
      </div>
    );
  }

  if (!response?.success) {
    return <Error />;
  }

  const {
    _id: course_id,
    courseName,
    courseDescription,
    thumbnail,
    tag,
    price,
    whatYouWillLearn,
    courseContent,
    ratingAndReviews,
    instructor,
    category,
    instructions,
    FirstContactNumber,
    SecondContactNumber,
    ThirdContactNumber,
    studentsEnrolled,
    createdAt,
  } = response?.data?.courseDetails || {};

  const handleBuyCourse = () => {
    if (token) {
      buyCourse(token, [courseId], user, navigate, dispatch);
      return;
    }
    setConfirmationModal({
      text1: "You are not logged in!",
      text2: "Please login to Book Now.",
      btn1Text: "Login",
      btn2Text: "Cancel",
      btn1Handler: () => navigate("/login"),
      btn2Handler: () => setConfirmationModal(null),
    });
  };

  if (paymentLoading) {
    return (
      <div className="grid min-h-[calc(100vh-3.5rem)] place-items-center">
        <div className="spinner"></div>
      </div>
    );
  }

return (
    <>
      <div className={`relative w-full bg-richblack-800`}>
        <div className="relative w-full bg-richblack-800 bg-cover bg-center ">
          <div className="hidden lg:block w-full">
            {thumbnail && (
              <img
                src={thumbnail}
                alt="Course Thumbnail"
                loading="lazy"
                className="w-full object-cover h-[350px]   "
              />
            )}
          </div>
        </div>

        <div className="mx-auto box-content px-4 lg:w-[1260px] 2xl:relative ">
          <div className="mx-auto grid min-h-[300px] max-w-maxContentTab justify-items-center 
          py-8 lg:mx-0 lg:justify-items-start lg:py-0 xl:max-w-[810px]">
            <div className="relative block max-h-[30rem] lg:hidden">
              <div className="absolute bottom-0 left-0 h-full w-full shadow-[#161D29_0px_-64px_36px_-28px_inset]"></div>
              {thumbnail && (
                <img
                  src={thumbnail}
                  alt="Course Thumbnail"
                  loading="lazy"
                  className="w-full object-cover h-[350px]"
                />
              )}
            </div>
            <div
              className={`z-30 flex flex-col justify-center gap-4 text-lg text-richblack-5 `}
            >
              <div>
                <p className="text-4xl font-bold text-richblack-5 sm:text-[42px]">
                  {courseName || 'Not available'}
                </p>
              </div>
              
              <div className="flex gap-x-8">
                  <div className="text-richblack-5">
                    {tag || 'No tag available'}
                  </div>
                  
              </div>

              <div className="text-richblack-5">
                    Rs. {price || 'No tag available'}
              </div>
              

              <ReactMarkdown>{whatYouWillLearn || "No data available"}</ReactMarkdown>
              <div className="text-md flex flex-wrap items-center gap-2">
                <RatingStars Review_Count={avgReviewCount} Star_Size={24} />
                <span>{`(${ratingAndReviews?.length || 0} reviews)`}</span>
              </div>
              <div className="flex gap-x-2">
                {FirstContactNumber && <div>{FirstContactNumber}</div>}
                {SecondContactNumber && <div>{SecondContactNumber}</div>}
                {ThirdContactNumber && <div>{ThirdContactNumber}</div>}
              </div>
            </div>
            <div className="flex w-full flex-col gap-4 py-4 lg:hidden">
              {/* <p className="space-x-3 pb-4 font-semibold text-richblack-5">
                Rs. {price || 'N/A'}
              </p> */}
            </div>

            {/* <div className="flex w-full flex-col gap-4 border-y border-y-richblack-500 py-4 lg:hidden">
              <button className="px-4 py-2 rounded bg-yellow-100 text-black 
              font-semibold hover:bg-yellow-50 transition duration-300" onClick={handleBuyCourse}>
                Book Now
              </button>
              <button className="px-4 py-2 rounded bg-yellow-100 text-black 
              font-semibold hover:bg-yellow-50 transition duration-300">Add to Wishlist</button>
            </div> */}
          </div>
           {/* Courses Card */}
            {/* <div className="  right-[1rem] lg:top-[60px] mx-auto  min-h-[600px] hidden  max-w-[410px] lg:translate-y-24 
            md:translate-y-0 lg:absolute  lg:block">
              <CourseDetailsCard
                course={response?.data?.courseDetails}
                setConfirmationModal={setConfirmationModal}
                handleBuyCourse={handleBuyCourse}
              />
            </div> */}
        </div>
      </div>
      <div className="mx-auto mt-10 box-content px-4 text-start text-richblack-5 lg:w-[1260px]">
        <div className="mx-auto max-w-maxContentTab lg:mx-0 xl:max-w-[810px]">
          <div className="max-w-[830px] ">
            <div className="flex flex-col gap-7">
              <div className="flex flex-wrap justify-between items-center ">
                <p className="text-[28px] font-semibold">Interior Image</p>
                <div>
                  <button
                    className="text-yellow-25"
                    onClick={() => setIsActive([])}
                  >
                    Collapse all sections
                  </button>
                </div>
              </div>
            </div>

            <div className="py-4">
              {courseContent?.map((course, index) => (
                <CourseAccordionBar
                  course={course}
                  key={index}
                  isActive={isActive}
                  handleActive={handleActive}
                />
              ))}
            </div>

            <div className="my-8 border border-richblack-600 p-8">
              <p className="text-3xl font-semibold">Features</p>
              <div className="mt-5">
                {instructions || "No instructions available"}
              </div>
            </div>

            <div className="mb-12 py-4">
              <p className="text-[28px] font-semibold">Owner</p>
              <div className="flex items-center gap-4 py-4">
                <img
                  src={
                    instructor?.image
                      ? instructor.image
                      : `https://api.dicebear.com/5.x/initials/svg?seed=${instructor?.firstName || ""} ${instructor?.lastName || ""}`
                  }
                  alt="Author"
                  className="h-14 w-14 rounded-full object-cover "
                  loading="lazy"
                />
                <p className="text-lg">{`${instructor?.firstName || ""} ${instructor?.lastName || ""}`}</p>
              </div>
              <p className="text-richblack-50">
                {instructor?.additionalDetails?.about || "No details available"}
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
      {confirmationModal && <ConfirmationModal modalData={confirmationModal} />}
    </>
  );
}

export default CourseDetails;
