import React, { useState } from 'react'
import {FaArrowRight} from "react-icons/fa"
import { Link, matchPath } from 'react-router-dom'
import HighlightText from '../componenets/core/HomePage/HighlightText'
import CTAButton from '../componenets/core/HomePage/Button'
import Banner from "../assets/Images/banner.mp4";
import CodeBlocks from '../componenets/core/HomePage/CodeBlocks'
import LearningLanguageSection from '../componenets/core/HomePage/LearningLanguageSection'
import TimelineSection from '../componenets/core/HomePage/TimelineSection'
import ExploreMore from '../componenets/core/HomePage/ExploreMore'
import InstructorSection from '../componenets/core/HomePage/InstructorSection'
import Footer from '../componenets/common/Footer'
import RatingSlider from '../componenets/core/Ratings/RatingSlider';
import Catalog from './Catalog'
import SearchBar from '../componenets/common/SearchBar'
import About from './About'

import { NavbarLinks } from '../data/Nav'
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { categories } from '../services/apis'
import { apiConnector } from '../services/apiconnector'
import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { useRef } from 'react'
import { useNavigate } from 'react-router'
import { toast } from "react-hot-toast"
import Item from './Item'



const Home = ({setProgress}) => {

  const dispatch = useDispatch();

  const { token } = useSelector(state => state.auth);
  const { user } = useSelector(state => state.profile);
  const { totalItems } = useSelector(state => state.cart);
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true)
  const [searchValue, setSearchValue] = useState("")
  const navigate = useNavigate();



  const location = useLocation()
  const matchRoutes = (routes) => {
      return matchPath({ path: routes }, location.pathname)
  }


  const [sublinks, setsublinks] = useState([]);
  const fetchSublinks = async () => {
      try {
          const result = await apiConnector("GET", categories.CATEGORIES_API);
          if (result?.data?.data?.length > 0) {
              setsublinks(result?.data?.data);
          }
          localStorage.setItem("sublinks", JSON.stringify(result.data.data));

      } catch (error) {
          // setsublinks(JSON.parse(localStorage.getItem("sublinks")));
          // console.log("could not fetch sublinks",localStorage.getItem("sublinks"));
          toast.error("Categories not found");
          console.log("Could not fetch Category",error);
      }
  }
  useEffect(() => {
      fetchSublinks();
  }, [])

  const show = useRef();
  const overlay = useRef();

  const shownav = () => {
      show.current.classList.toggle('navshow');
      overlay.current.classList.toggle('hidden');
  }

  

  //handeling navbar scroll
  const handleScroll = () => {
      const currentScrollPos = window.scrollY

      if (currentScrollPos > prevScrollPos) {
          setVisible(false)
      } else {
          setVisible(true)
      }

      setPrevScrollPos(currentScrollPos)
  }

  useEffect(() => {
      window.addEventListener('scroll', handleScroll);

      return () => window.removeEventListener('scroll', handleScroll)
  })

  const handelSearch = (e) => {
      e.preventDefault();
      if (searchValue?.length > 0) {
          navigate(`/search/${searchValue}`);
          setSearchValue("");
      }
  }





  return (
    <div>
      {/*section1*/}
      <div className="relative mx-auto flex  max-w-maxContent flex-col 
         items-center justify-between gap-8 text-white">
      
        
        <div className=" text-center text-2xl md:text-4xl font-semibold mt-20">
              Find Your Ideal Space 
             <HighlightText text={"With Ease"} />
         </div>
        
         <SearchBar/> 
         
         <div className='flex flex-row gap-7 mt-8'>
          
         </div>
        
        <Item></Item>
        
      </div>

    <div className='mt-10'> <About/> </div>

    </div>
  )
}

export default Home
