import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import { categories } from "../services/apis";
import Footer from "../componenets/common/Footer";
import { apiConnector } from "../services/apiconnector";
import CourseSlider from "../componenets/core/Catalog/CourseSlider";
import { getCatalogaPageData } from "../services/operations/pageAndComponentData";
import CatalogCard from "../componenets/core/Catalog/CatalogCard";
import { useDispatch } from "react-redux";
import { MdLocationOn } from "react-icons/md"; // Import location icon
import toast from "react-hot-toast";



const API_KEY = "d1845658f92b31c64bd94f06f7188c9c"; // OpenWeatherMap API Key

const Catalog = () => {
  const Catalog = useParams();
  const [Desc, setDesc] = useState([]);
  const [CatalogPageData, setCatalogPageData] = useState(null);
  const [categoryID, setCategoryID] = useState(null);
  const [active, setActive] = useState(1);
  const [searchQuery, setSearchQuery] = useState(""); // State for search input
  const [filteredCourses, setFilteredCourses] = useState([]); // State for filtered courses
  const [priceRange, setPriceRange] = useState({ min: "", max: "" }); // State for price range
  const dispatch = useDispatch();

  // Fetch Category ID and Description
  const fetchSublinks = async () => {
    try {
      const result = await apiConnector("GET", categories.CATEGORIES_API);
      const category = result.data.data.filter(
        (item) => item.name === Catalog.catalog
      )[0];
      setCategoryID(category._id);
      setDesc(category);
    } catch (error) {
      console.log("Could not fetch sublinks");
      console.log(error);
      toast.error("COULD NOT FETCH LINK");
    }
  };

  // Fetch Catalog Page Data
  useEffect(() => {
    const fetchCatalogPageData = async () => {
      const result = await getCatalogaPageData(categoryID, dispatch);
      setCatalogPageData(result);
      setFilteredCourses(
        result?.data?.selectedCategory?.courses?.sort(() => Math.random() - 0.5) || []
      ); // Initialize filtered courses in random order
    };
    if (categoryID) {
      fetchCatalogPageData();
    }
  }, [categoryID]);

  useEffect(() => {
    fetchSublinks();
  }, [Catalog]);

  // Update Filtered Courses Based on Search and Price Range
  useEffect(() => {
    const allCourses = CatalogPageData?.data?.selectedCategory?.courses || [];

    // Filter courses by search query
    let filtered = allCourses.filter((course) =>
      course?.courseDescription?.toLowerCase().includes(searchQuery.toLowerCase())
    );

    // Filter courses by price range
    if (priceRange.min !== "" && priceRange.max !== "") {
      filtered = filtered.filter(
        (course) =>
          course.price >= parseFloat(priceRange.min) &&
          course.price <= parseFloat(priceRange.max)
      );
    }

    // Shuffle the filtered courses
    const shuffledCourses = filtered.sort(() => Math.random() - 0.5);

    setFilteredCourses(shuffledCourses);
  }, [searchQuery, priceRange, CatalogPageData]);

  // Handle search input changes
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  // Handle price range input changes
  const handlePriceRangeChange = (e) => {
    const { name, value } = e.target;
    setPriceRange((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <>
      {/* Hero Section */}
      <div className="box-content bg-richblack-800 px-4">
        <div
          className="mx-auto flex min-h-[260px] max-w-maxContentTab flex-col justify-center gap-4 
          lg:max-w-maxContent"
        >
          <p className="text-3xl text-yellow-25">
            {CatalogPageData?.data?.selectedCategory?.name}
          </p>
          <p className="max-w-[870px] text-richblack-200">
            {CatalogPageData?.data?.selectedCategory?.description}
          </p>

          {/* Search Bar */}
          <div className="lg:flex md:flex">
            <div className="relative w-full max-w-md">
              <input
                type="text"
                placeholder="Search properties by city name"
                value={searchQuery}
                onChange={handleSearchChange}
                className="w-full text-xs font-bold py-4 pl-3 pr-16  border border-gray-400 
                          focus:outline-none focus:ring-2 focus:ring-yellow-500"
              />
            </div>

            {/* Price Range Filter */}
            <div className="price-filter">
              <div className="flex items-center">
                <input
                  type="text"
                  name="min"
                  placeholder="Min Price"
                  value={priceRange.min}
                  onChange={handlePriceRangeChange}
                  className="w-full text-xs font-bold py-4 pl-3 pr-16  border border-gray-400 
                          focus:outline-none focus:ring-2 focus:ring-yellow-500"
                />

                <input
                  type="text"
                  name="max"
                  placeholder="Max Price"
                  value={priceRange.max}
                  onChange={handlePriceRangeChange}
                  className="w-full text-xs font-bold py-4 pl-3 pr-16 border border-gray-400 
                          focus:outline-none focus:ring-2 focus:ring-yellow-500"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Section 1 */}
      <div className="mx-auto box-content w-full max-w-maxContentTab px-4 py-12 lg:max-w-maxContent">
        <div className="my-4 flex border-b border-b-richblack-600 text-sm">
          <p
            className={`px-4 py-2 font-extrabold ${
              active === 1
                ? "border-b border-b-yellow-25 text-yellow-25"
                : "text-richblack-50"
            } cursor-pointer`}
            onClick={() => setActive(1)}
          >
            Most Popular
          </p>
          <p
            className={`px-4 py-2 font-extrabold ${
              active === 2
                ? "border-b border-b-yellow-25 text-yellow-25"
                : "text-richblack-50"
            } cursor-pointer`}
            onClick={() => setActive(2)}
          >
            New
          </p>
        </div>
        <div>
          <CourseSlider Courses={filteredCourses} />
        </div>
      </div>

      {/* Section 2 */}
      <div className="mx-auto box-content w-full max-w-maxContentTab px-4 py-12 lg:max-w-maxContent">
        <div className="section_heading font-extrabold md:text-xl text-richblack-5 text-center">
          Top Properties in {CatalogPageData?.data?.differentCategory?.name}
        </div>
        <div className="py-8">
          <CourseSlider
            Courses={
              CatalogPageData?.data?.differentCategory?.courses?.sort(
                () => Math.random() - 0.5
              )
            }
          />
        </div>
      </div>

      {/* Section 3 */}
      <div className="mx-auto box-content w-full max-w-maxContentTab px-4 py-14 lg:max-w-maxContent">
        <div className="section_heading font-extrabold md:text-xl text-richblack-5 text-center py-8">
          Frequently Visited
        </div>
        <div className="py-8">
          <div className="grid grid-cols-2 gap-6 lg:grid-cols-3">
            {CatalogPageData?.data?.mostSellingCourses
              ?.sort(() => Math.random() - 0.5)
              ?.slice(0, 6)
              .map((course, i) => (
                <CatalogCard course={course} key={i} Height={"h-[200px]"} />
              ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Catalog;
