import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import IconBtn from "../../../common/IconBtn"
import { buyCourse } from "../../../../services/operations/studentFeaturesAPI"

export default function RenderTotalAmount() {
  const { total, cart } = useSelector((state) => state.cart)
  const { token } = useSelector((state) => state.auth)
  const { user } = useSelector((state) => state.profile)
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const handleBuyCourse = () => {
    const courses = cart.map((course) => course._id)
    buyCourse(token, courses, user, navigate, dispatch)
  }

  return (
    <div
      className=" flex flex-col items-center lg:m-[100px] m-4 mx-auto rounded-md border-[1px] border-richblack-700
       bg-richblack-800 p-6 mt-10 sm:mt-20 sm:mr-0"
    >
      <p className="mb-1 text-sm font-medium text-richblack-300 text-center sm:text-left">
        Total:
      </p>
      <p className="mb-6 text-3xl font-medium text-yellow-100 text-center sm:text-left">
        ₹ {total}
      </p>
      <IconBtn
        text="Book Now"
        onclick={handleBuyCourse}
        customClasses="w-full justify-center"
      />

      {/* <button onclick={handleBuyCourse}
        className="w-[80%] flex items-center justify-center gap-x-1 px-4 py-2 rounded bg-yellow-100 text-black font-semibold hover:bg-yellow-50 transition duration-300">
          Book Now
      </button> */}
    </div>
  );
  
}