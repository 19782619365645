import { FaStar } from "react-icons/fa"
import { RiDeleteBin6Line } from "react-icons/ri"
import ReactStars from "react-rating-stars-component"
import { useDispatch, useSelector } from "react-redux"
import { removeFromCart } from "../../../../slices/cartSlice"

export default function RenderCartCourses() {
  const { cart } = useSelector((state) => state.cart)
  const dispatch = useDispatch()
  return (
    <div className="flex flex-1 flex-col lg:m-[100px] m-4">
      {cart.map((course, indx) => (
        <div
          key={course._id}
          className={`flex flex-col w-full items-center gap-6 ${
            indx !== cart.length - 1 && "border-b border-b-richblack-400 pb-6"
          } ${indx !== 0 && "mt-6"} lg:flex-row lg:justify-between`}
        >
          {/* Course Details Section */}
          <div className="flex flex-col items-center w-full gap-4 lg:flex-row lg:items-start lg:w-auto">
            <img
              src={course?.thumbnail}
              alt={course?.courseName}
              loading="lazy"
              className="h-[148px] w-full lg:w-[220px] rounded-lg object-cover"
            />
            <div className="flex flex-col items-center lg:items-start w-full lg:w-auto space-y-1">
              <p className="text-lg font-medium text-richblack-5 text-center lg:text-left">
                {course?.courseName}
              </p>
              <p className="text-sm text-richblack-300 text-center lg:text-left">
                {course?.category?.name}
              </p>
              <div className="flex items-center gap-2">
                <span className="text-yellow-5">4.5</span>
                <ReactStars
                  count={5}
                  value={course?.ratingAndReviews?.length}
                  size={20}
                  edit={false}
                  activeColor="#ffd700"
                  emptyIcon={<FaStar />}
                  fullIcon={<FaStar />}
                />
                <span className="text-richblack-400">
                  {course?.ratingAndReviews?.length} Ratings
                </span>
              </div>
            </div>
          </div>
  
          {/* Pricing and Remove Button Section */}
          <div className="flex flex-col items-center w-full lg:w-auto lg:items-end 
          lg:space-y-2 -mt-6 lg:mt-0">
            <p className="text-2xl lg:text-3xl font-medium text-yellow-100 mb-3 lg:mb-0">
              ₹ {course?.price}
            </p>
            <button
              onClick={() => dispatch(removeFromCart(course._id))}
              className="w-[80%] flex items-center justify-center gap-x-1
               px-4 py-2 rounded bg-yellow-100 text-black 
              font-semibold hover:bg-yellow-50 transition duration-300"
            >
              <RiDeleteBin6Line />
              <span>Remove</span>
            </button>
          </div>
        </div>
      ))}
    </div>
  );
  
  
  
}