import React, { useState } from 'react'
import logo from "../../assets/Logo/my-logo.png"
import { Link, matchPath } from 'react-router-dom'
import { NavbarLinks } from '../../data/navbar-links'
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { TiShoppingCart } from 'react-icons/ti'
import ProfileDropDown from '../core/Auth/ProfileDropDown'
import { categories } from '../../services/apis'
import { apiConnector } from '../../services/apiconnector'
import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { GiHamburgerMenu } from 'react-icons/gi'
import { useRef } from 'react'
import { HiSearch } from 'react-icons/hi'
import { useNavigate } from 'react-router'
import { toast } from "react-hot-toast"
import HighlightText from '../core/HomePage/HighlightText'
import SearchBar from "./SearchBar"
const NavBar = ({ setProgress }) => {
    const dispatch = useDispatch();

    const { token } = useSelector(state => state.auth);
    const { user } = useSelector(state => state.profile);
    const { totalItems } = useSelector(state => state.cart);
    const [prevScrollPos, setPrevScrollPos] = useState(0);
    const [visible, setVisible] = useState(true)
    const [searchValue, setSearchValue] = useState("")
    const navigate = useNavigate();



    const location = useLocation()
    const matchRoutes = (routes) => {
        return matchPath({ path: routes }, location.pathname)
    }


    const [sublinks, setsublinks] = useState([]);
    const fetchSublinks = async () => {
        try {
            const result = await apiConnector("GET", categories.CATEGORIES_API);
            if (result?.data?.data?.length > 0) {
                setsublinks(result?.data?.data);
            }
            localStorage.setItem("sublinks", JSON.stringify(result.data.data));

        } catch (error) {
            // setsublinks(JSON.parse(localStorage.getItem("sublinks")));
            // console.log("could not fetch sublinks",localStorage.getItem("sublinks"));
            toast.error("Categories not found");
            console.log("Could not fetch Category",error);
        }
    }
    useEffect(() => {
        fetchSublinks();
    }, [])

    const show = useRef();
    const overlay = useRef();

    const shownav = () => {
        show.current.classList.toggle('navshow');
        overlay.current.classList.toggle('hidden');
    }

    

    //handeling navbar scroll
    const handleScroll = () => {
        const currentScrollPos = window.scrollY

        if (currentScrollPos > prevScrollPos) {
            setVisible(false)
        } else {
            setVisible(true)
        }

        setPrevScrollPos(currentScrollPos)
    }

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => window.removeEventListener('scroll', handleScroll)
    })

    const handelSearch = (e) => {
        e.preventDefault();
        if (searchValue?.length > 0) {
            navigate(`/search/${searchValue}`);
            setSearchValue("");
        }
    }


    return (
        <div className="flex flex-col bg-richblack-900 relative z-50  items-center justify-center 
        border-b-[1px] border-b-richblack-700">
          {/* First Row */}
          <div className="flex w-full max-w-screen-xl items-center justify-between px-4 lg:px-6 sm:px-2 flex-row space-x-4">
            {/* Logo */}
            <Link to="/" onClick={() => { dispatch(setProgress(100)) }}>
              <img src={logo} loading="lazy" width={80} alt="Study Notion" height={60} />
            </Link>
      
            {/* Mobile Navbar */}
            <div className="flex lg:hidden  relative">
              <GiHamburgerMenu
                className="w-8 h-8 mr-4 fill-richblack-25 cursor-pointer"
                onClick={shownav}
              />
              <div ref={overlay} className="fixed inset-0 z-30 hidden bg-[rgba(0,0,0,0.5)]" 
              onClick={shownav}></div>
              <div ref={show} className="mobNav transition-all duration-300 z-50">
                <nav className="flex items-center flex-col absolute max-w-[140px] top-12 -left-32
                 bg-richblack-900 p-4">
                  {token == null && (
                    <>
                      <Link to="/login" onClick={() => { dispatch(setProgress(100)) }}>
                        <button onClick={shownav}
                         className="mt-4 scale-50 text-center
                                         text-[15px] px-6 py-2 rounded-md font-semibold
                                         bg-yellow-50 text-black  transition-all duration-200">
                          Login
                        </button>
                      </Link>
                      <Link to="/signup" onClick={() => { dispatch(setProgress(100)) }}>
                        <button onClick={shownav}
                         className="mt-2 scale-50 text-center
                                         text-[15px] px-6 py-2 rounded-md font-semibold
                                         bg-yellow-50 text-black  transition-all duration-200">
                          Signup
                        </button>
                      </Link>
                    </>
                  )}
                  {token !== null && (
                    <div className="mt-2">
                      <p className="text-richblack-50 mb-2 text-center">Account</p>
                      <ProfileDropDown />
                    </div>
                  )}
                  <div className="mt-4 mb-4 bg-richblack-25 w-full h-[2px]"></div>
                  <Link to="/about" onClick={() => { dispatch(setProgress(100)); shownav() }} className="p-2">
                    <p className="text-richblack-5 text-center scale-50">About</p>
                  </Link>
                  <Link to="/contact" onClick={() => { dispatch(setProgress(100)); shownav() }} className="p-2">
                    <p className="text-richblack-5 text-center scale-50">Contact</p>
                  </Link>
                  <Link to="/" onClick={() => { dispatch(setProgress(100)); shownav() }} className="p-2">
                    <p className="text-richblack-5 text-center scale-50">Home</p>
                  </Link>
                  <Link to="/dashboard/my-profile" onClick={() => { dispatch(setProgress(100)); shownav() }} className="p-2">
                    <p className="text-richblack-5 text-center scale-50">Rent</p>
                  </Link>
                </nav>
              </div>
            </div>
      
            {/* Navigation Links - Desktop */}
            <nav className="hidden lg:flex flex-row items-center gap-6">
              <Link to="/about" onClick={() => { dispatch(setProgress(100)) }}>
                <p className="text-richblack-25 hover:text-richblack-50 mx-4">About Us</p>
              </Link>
              <Link to="/contact" onClick={() => { dispatch(setProgress(100)) }}>
                <p className="text-richblack-25 hover:text-richblack-50 mx-4">Contact Us</p>
              </Link>
              <Link to="/dashboard/my-profile">
                <p className="text-richblack-25 hover:text-richblack-50 mx-4">Rent Your Property</p>
              </Link>
            </nav>
      
            {/* Auth Buttons */}
            <div className="hidden lg:flex flex-row gap-4">
              {token == null && (
                <>
                  <Link to="/login" onClick={() => { dispatch(setProgress(100)) }}>
                    <button className="rounded-[8px] border border-richblack-700 bg-richblack-800 px-[12px] py-[7px] text-richblack-100 hover:bg-richblack-700">
                      Login
                    </button>
                  </Link>
                  <Link to="/signup" onClick={() => { dispatch(setProgress(100)) }}>
                    <button className="rounded-[8px] border border-richblack-700 bg-richblack-800 px-[12px] py-[7px] text-richblack-100 hover:bg-richblack-700">
                      Signup
                    </button>
                  </Link>
                </>
              )}
              {token !== null && <ProfileDropDown />}
            </div>
          </div>
      
          
        </div>
      );
      
      
      
      
      
}

export default NavBar