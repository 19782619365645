import React, { useEffect } from 'react';
import OTPInput from 'react-otp-input';
import { useSelector, useDispatch } from 'react-redux';
import { signUp } from '../services/operations/authAPI';
import { useNavigate } from 'react-router-dom';

const VerifyOtp = () => {
  const [otp, setOtp] = React.useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, signupData } = useSelector((state) => state.auth);

  useEffect(() => {
    if (!signupData) {
      navigate('/signup');
    }
  }, [signupData, navigate]);

  const handleOnSubmit = (e) => {
    e.preventDefault();
    const { email, accountType, confirmPassword, password, lastName, firstName } = signupData;

    dispatch(
      signUp(
        firstName,
        lastName,
        email,
        password,
        confirmPassword,
        accountType,
        otp,
        navigate
      )
    );
  };

  return loading ? (
    <div className="h-[100vh] flex justify-center items-center">
      <div className="custom-loader"></div>
    </div>
  ) : (
    <div>
      <div className="min-h-[calc(100vh-3.5rem)] grid place-items-center px-4">
        <div className="w-full max-w-[500px] bg-richblack-800 p-6 md:p-8 rounded-lg shadow-lg">
          <h1 className="text-richblack-5 font-semibold text-[1.875rem] leading-[2.375rem] text-center">
            Verify Email
          </h1>
          <p className="text-[1.125rem] leading-[1.625rem] my-4 text-richblack-100 text-center">
            A verification code has been sent to you. Enter the code below.
          </p>
          <form onSubmit={handleOnSubmit} className="space-y-6">
            <div className="flex justify-center">
              <OTPInput
                value={otp}
                onChange={setOtp}
                numInputs={6}
                renderSeparator={<span className="text-richblack-500">-</span>}
                inputStyle="w-[45px] md:w-[60px] h-[50px] md:h-[70px] rounded-md border-[1px] border-richblack-500 text-[1.5rem] md:text-[2rem] text-center bg-richblack-900 text-white"
                focusStyle="border-[2px] border-yellow-500"
                isInputNum={true}
                shouldAutoFocus={true}
                containerStyle="flex justify-between gap-2 md:gap-4"
                renderInput={(props) => <input {...props} />}
              />
            </div>
            <button
              type="submit"
              className="w-full bg-yellow-50 py-3 px-4 rounded-md font-medium text-richblack-900 hover:bg-yellow-100 transition duration-300"
            >
              Verify Email
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default VerifyOtp;
