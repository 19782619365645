import RenderSteps from "./RenderSteps"
import registration from "../../../../assets/Logo/registration.webp"

export default function AddCourse() {
  return (
    <>
      <div className=" lg:flex w-full  gap-x-6 bg-richblack-900 mb-40 justify-start">
        <div className="flex flex-1 flex-col lg:w-[70%]">
          <h1 className="mb-14 text-3xl font-medium text-richblack-5 text-center mt-2">
            Add Property
          </h1>
          <div className="flex-1">
            <RenderSteps />
          </div>
        </div>
        {/* Course Upload Tips */}
        {/* <div className="sticky mr-10 justify-center top-10 hidden max-w-[300px] flex-1 rounded-md border-[1px] border-richblack-700 bg-richblack-800 p-6 xl:block">
          <p className="mb-8 text-lg text-richblack-5">⚡ Property Upload Tips</p>
          <ul className="ml-5 list-item list-disc space-y-4 text-xs text-richblack-5">
            <li>Standard size for the property thumbnail is 1024x576.</li>
            <li>Property Builder is where you create & organize a property.</li>
            <li>
              Add images in the Interior section to create visual appearance.
            </li>
            <li>
              Read the Term and Condition properly.
            </li>
            <li>The Information provided should be Correct.</li>
            <li>The Rent Amount Should be fare.</li>
          </ul>
        </div> */}

        {/* New Video Section */}
        <div className="flex lg:w-[30%]  flex-col items-center justify-center lg:justify-start
                       lg:mt-60 px-16 mt-12 ">
                  {/* Video Thumbnail with Link */}
                  <div className="w-full ">
                    <a
                      href="https://youtu.be/0XvRMv0Edbc?si=464Q5lL5MPUJJZH6"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={registration}
                        alt="Click to Watch Video"
                        className="cursor-pointer w-full object-cover rounded-lg shadow-lg 
                                   hover:opacity-90 "
                      />
                    </a>
                  </div>
                  <div className="flex mt-4">
                    {/* Button with Link */}
                    <a
                      href="https://youtu.be/0XvRMv0Edbc?si=464Q5lL5MPUJJZH6"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button
                        className="flex items-center justify-center gap-x-2 rounded-md bg-yellow-50 
                        py-2 px-14 text-sm font-semibold text-richblack-900 hover:bg-yellow-100 
                        sm:text-base md:text-lg"
                      >
                        Watch Now
                      </button>
                    </a>
                  </div>
        </div>
      </div>
    </> 
  )
}