import { HiSearch } from "react-icons/hi";
import { MdLocationOn } from "react-icons/md"; // Import location icon
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const API_KEY = "d1845658f92b31c64bd94f06f7188c9c"; // Your OpenWeatherMap API Key

const SearchBar = () => {
    const [searchValue, setSearchValue] = useState("");
    const navigate = useNavigate();

    const handleSearch = (e) => {
        e.preventDefault();
        if (searchValue?.length > 0) {
            navigate(`/search/${searchValue}`);
            setSearchValue("");
        } else {
            alert("Please enter a search term or click the location icon to use your current location.");
        }
    };

    const handleLocationSearch = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                async (position) => {
                    const { latitude, longitude } = position.coords;

                    try {
                        // Fetch city name using reverse geocoding API
                        const response = await fetch(
                            `https://api.openweathermap.org/geo/1.0/reverse?lat=${latitude}&lon=${longitude}&limit=1&appid=${API_KEY}`
                        );
                        const data = await response.json();

                        if (data && data[0]?.name) {
                            navigate(`/search/${data[0].name}`);
                        } else {
                            alert("Unable to determine your city. Please enter a search term.");
                        }
                    } catch (error) {
                        alert("An error occurred while fetching location data. Please try again.");
                    }
                },
                (error) => {
                    alert("Location access denied or unavailable. Please enter a search term.");
                }
            );
        } else {
            alert("Geolocation is not supported by your browser.");
        }
    };

    return (
        <div className="flex w-full items-center justify-center
          mt-6 lg:mt-10 md:mt-10 -mb-8 md:mb-6 lg:mb-6">
            <form onSubmit={handleSearch} className="flex  w-[90%] lg:w-[60%] md:w-[60%] items-center relative h-20">
                <input
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                    id="searchinput"
                    type="text"
                    placeholder="Search by city name"
                    className="absolute top-0 left-0 rounded-3xl focus:ring-2
                      w-full h-14 border-2 border-yellow-400 pl-2
                      md:px-8 lg:px-10 py-6 text-sm font-bold text-black focus:outline-none
                     bg-white"
                />
                <div
                    className="flex items-center absolute lg:right-10 right-5 top-3 lg:top-3
                    cursor-pointer text-xs whitespace-nowrap"
                    onClick={handleLocationSearch}
                >
                    <MdLocationOn size={28} className="text-yellow-500" />
                    <p className="text-black text-sm font-bold mt-1">Near Me</p>
                </div>
            </form>
            {/* <button
                onClick={handleSearch}
                className="ml-4 mr-2 -mt-5 p-3 bg-yellow-500 text-white font-bold rounded-full hover:bg-yellow-600 transition-all flex items-center justify-center"
            >
                <HiSearch size={10} />
            </button> */}
        </div>
    );
};

export default SearchBar;
