import { FcGoogle } from "react-icons/fc";
import { useSelector } from "react-redux";
import LoginForm from "./LoginForm";
import SignupForm from "./SignupForm";
import registration from '../../../assets/Logo/registration.webp';

function Template({ title, description1, description2, image, formType }) {
  const { loading } = useSelector((state) => state.auth);

  return (
    <div className="grid min-h-[calc(100vh-3.5rem)] place-items-center">
      {loading ? (
        <div className="spinner"></div>
      ) : (
        <div className="mx-auto flex w-11/12 max-w-maxContent flex-col
        justify-between gap-y-12 py-12 md:flex-row md:gap-y-0 md:gap-x-12">
          {/* Text and Form Section */}
          <div className="mx-auto w-11/12 max-w-[450px] md:mx-0">
            <h1 className="text-[1.875rem] font-semibold leading-[2.375rem] text-richblack-5">
              {title}
            </h1>
            <p className="mt-4 text-[1.125rem] leading-[1.625rem]">
              <span className="text-richblack-100">{description1}</span>{" "}
              <span className="font-edu-sa font-bold italic text-blue-100">
                {description2}
              </span>
            </p>
            {formType === "signup" ? <SignupForm /> : <LoginForm />}
          </div>

          {/* Image Section with Video Link */}
          <div className="relative mx-auto w-11/12 max-w-[450px] md:mx-0">
            {/* <img
              src={image}
              alt="Pattern"
              width={558}
              height={504}
              loading="lazy"
              className="rounded-lg shadow-lg"
            /> */}
            <div className="flex flex-col items-center justify-center mt-4">
              {/* Video Thumbnail with Link */}

              <div> 
                  <a
                    href="https://youtu.be/0XvRMv0Edbc?si=464Q5lL5MPUJJZH6"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={registration}
                      alt="Click to Watch Video"
                      className="cursor-pointer h-96 rounded-lg shadow-lg hover:opacity-90"
                    />
                  </a>

              </div>

              <div className="flex mt-4">
                {/* Button with Link */}
                    <a
                      href="https://youtu.be/0XvRMv0Edbc?si=464Q5lL5MPUJJZH6"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button
                        className="flex items-center justify-center gap-x-2 rounded-md bg-yellow-50 
                        py-2 px-36 text-sm font-semibold text-richblack-900 hover:bg-yellow-100 
                        sm:text-base md:text-lg"
                      >
                        Watch Now
                      </button>
                    </a>
              </div>
              
              
              
            </div>
          </div>

        </div>
      )}
    </div>
  );
}

export default Template;
